import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './EduGames.css';  
import ishitori from '../assets/ishitori.png';
import yuzyou from '../assets/yuzyou.png';

function EduGames() {
    return (
        <div className='edugame-page'>
            <div className='game-list'>
                <div className='isitori-game'>
                    <p>○石取りゲーム</p>
                    <a href="https://edushare.jp/games/ishitori/ishitori.html" target="_blank"><img src={ishitori} alt="石取りゲーム" className='game-images'/></a>
                    <div className='game-explanation'>数学Aで学習する必勝法の内容をゲーム化したもの。1〜3個ずつ石を取り合い、指定された個数の最後の石をとった方が勝ち。</div>
                </div>
                <div className='isitori-game'>
                    <p>○友情の試練</p>
                    <a href="https://edushare.jp/games/yuzyou/yuzyou.html" target="_blank"><img src={yuzyou} alt="友情の試練" className='game-images'/></a>
                    <div className='game-explanation'>ナッシュ均衡を体験できるゲーム。両者「協力」なら両者に3pt。両者「裏切り」なら両者に1pt。片方のみ「裏切り」なら裏切った者に5pt。
                    </div>
                </div>
            </div>
                
        </div>

    )
      }
export default EduGames;