import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png';
import mushimegane from '../assets/mushimegane.png';
import { AuthContext } from '../contexts/AuthContext'; // AuthContextをインポート

const Header = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [keyword, setKeyword] = useState(''); // フリーワード検索用の状態
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext); // ログイン状態を取得
  const navigate = useNavigate();

  // ローカルストレージからroleを取得
  const role = localStorage.getItem('role');  // 追加: ロール情報を取得

  const handleMouseEnter = (tab) => {
    setActiveTab(tab);
  };

  const handleMouseLeave = () => {
    setActiveTab(null);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    // 検索ワードをパスパラメータとしてMaterialSearchに渡す
    navigate(`/MaterialSearch/${encodeURIComponent(keyword)}`);
  };

  // ログアウト処理
  const handleLogout = () => {
    const confirmLogout = window.confirm('本当にログアウトしますか？'); // 確認ダイアログ
    if (confirmLogout) {
      localStorage.removeItem('token'); // トークンを削除してログアウト
      localStorage.removeItem('role'); // ロールも削除
      setIsLoggedIn(false); // ログアウト状態にする
      navigate('/'); // ホームページにリダイレクト
    }
  };

  const handleNavigation = (path) => {
    navigate(path); // ボタンを押すと特定のページに遷移
  };

  const subjects = {
    elementary: [
      <Link to="/elem-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
      <Link to="/elem-so" className='sub'>社&emsp;&emsp;&emsp;会</Link>, 
      <Link to="/elem-ma" className='sub'>算&emsp;&emsp;&emsp;数</Link>, 
      <Link to="/elem-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
      <Link to="/elem-li" className='sub'>生&emsp;&emsp;&emsp;活</Link>, 
      <Link to="/elem-mu" className='sub'>音&emsp;&emsp;&emsp;楽</Link>, 
      <Link to="/elem-ar" className='sub'>図 画 工 作</Link>, 
      <Link to="/elem-ho" className='sub'>家&emsp;&emsp;&emsp;庭</Link>, 
      <Link to="/elem-ph" className='sub'>保 健 体 育</Link>,
      <Link to="/elem-fo" className='sub'>外国語活動</Link>
    ],
    juniorHigh: [
      <Link to="/juni-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
      <Link to="/juni-so" className='sub'>社&emsp;&emsp;&emsp;会</Link>, 
      <Link to="/juni-ma" className='sub'>数&emsp;&emsp;&emsp;学</Link>, 
      <Link to="/juni-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
      <Link to="/juni-fo" className='sub'>外&emsp;国&emsp;語</Link>, 
      <Link to="/juni-mu" className='sub'>音&emsp;&emsp;&emsp;楽</Link>, 
      <Link to="/juni-ar" className='sub'>美&emsp;&emsp;&emsp;術</Link>, 
      <Link to="/juni-ph" className='sub'>保 健 体 育</Link>,
      <Link to="/juni-th" className='sub'>技 術 家 庭</Link>
    ],
    high: [
      <Link to="/high-ja" className='sub'>国&emsp;&emsp;&emsp;語</Link>, 
      <Link to="/high-gh" className='sub'>地 理 歴 史</Link>,
      <Link to="/high-pu" className='sub'>公&emsp;&emsp;&emsp;民</Link>,  
      <Link to="/high-ma" className='sub'>数&emsp;&emsp;&emsp;学</Link>, 
      <Link to="/high-sc" className='sub'>理&emsp;&emsp;&emsp;科</Link>,
      <Link to="/high-fo" className='sub'>外&emsp;国&emsp;語</Link>, 
      <Link to="/high-ph" className='sub'>保 健 体 育</Link>, 
      <Link to="/high-ar" className='sub'>芸&emsp;&emsp;&emsp;術</Link>, 
      <Link to="/high-ho" className='sub'>家&emsp;&emsp;&emsp;庭</Link>,
      <Link to="/high-it" className='sub'>情&emsp;&emsp;&emsp;報</Link>,
      <Link to="/high-sp" className='sub'>専 門 科 目</Link> 
    ],
    others: [
      <Link to="/kinder-garden" className='sub'>幼&emsp;稚&emsp;園</Link>, 
      <Link to="/support-sc" className='sub'>特別支援学校</Link>,
      <Link to="/home-room" className='sub'>学活・ホームルーム</Link>,
    ]
  };

  return (
    <header className="header">
      <div className="header-content">
        <a href="/">
          <img src={logo} alt="Logo" className="logo" />
        </a>

        <div className='btn-freeword'>
          <div className="header-btn">
            {!isLoggedIn ? (
              <>
                <button onClick={() => handleNavigation('/login')} className="btn login">ロ グ イ ン</button>
                <button onClick={() => handleNavigation('/register')} className="btn register">会 員 登 録</button>
              </>
            ) : (
              <>
                              {role === 'admin' && ( // 管理者の場合のみ表示
                  <button onClick={() => handleNavigation('/admin')} className="btn admin">管理者用ページ</button>
                )}
                <button onClick={() => handleNavigation('/MyPage')} className="btn mypage">マイページ</button>
                <button onClick={handleLogout} className="btn logout">ログアウト</button>
              </>
            )}
          </div>

          <div className="freeword-search">
            <form onSubmit={handleSearch}> {/* フォームをsubmitでhandleSearchを実行 */}
              <label htmlFor="Keyword"></label>
              <input 
                type="text" 
                id="Keyword" 
                value={keyword} 
                className="search-box" 
                placeholder="何をお探しですか？" 
                onChange={e => setKeyword(e.target.value)} 
              />
              <div className='search-item'>
                <img 
                  src={mushimegane} 
                  alt="Search" 
                  className='mushimegane' 
                  onClick={handleSearch} 
                  style={{cursor: 'pointer'}} 
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <nav className="tabs">
  {Object.keys(subjects).map((tab) => (
    <div 
      key={tab} 
      className={`tab ${tab}`} 
      onMouseEnter={() => handleMouseEnter(tab)} 
      onMouseLeave={handleMouseLeave}
    >
      <a href={`#${tab}`} className={`tab ${tab}`}>
        {tab === 'elementary' ? (
          <Link to="/elem">小　学　校</Link>
        ) : tab === 'juniorHigh' ? (
          <Link to="/juni">中　学　校</Link>
        ) : tab === 'high' ? (
          <Link to="/high">高 等 学 校</Link>
        ) : (
          <Link to="/other">そ　の　他</Link>
        )}
      </a>
      {activeTab === tab && (
        <div className="dropdown-menu">
          {subjects[tab].map((subject, index) => (
            <div key={index} className="dropdown-item">
              {subject}
            </div>
          ))}
        </div>
      )}
    </div>
  ))}

  {/* 教育系ゲームタブを追加 */}
  <div 
    className="tab games" 
    onMouseEnter={() => handleMouseEnter('games')} 
    onMouseLeave={handleMouseLeave}
  >
    <a href="#games" className="tab games">
      <Link to="/EduGames">教育ゲーム</Link>
    </a>
  </div>
</nav>
    </header>
  );
};

export default Header;
